.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
.App {
    font-family: sans-serif;
    text-align: center;
    }
    .box {
    position: relative;
    }
    .box-entered {
    color: red;
    }
    .box-entering {
    animation-name: example;
    animation-duration: 4s;
    }
    @keyframes example {
    0% {
    color: red;
    left: 0px;
    top: 0px;
    }
    25% {
    color: yellow;
    left: 200px;
    top: 0px;
    }
    50% {
    color: blue;
    left: 200px;
    top: 200px;
    }
    75% {
    color: green;
    left: 0px;
    top: 200px;
    }
    100% {
    color: red;
    left: 0px;
    top: 0px;
    }
}
.App-header {
    background-color: #343A40;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}